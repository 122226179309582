import 'bootstrap';
import '@popperjs/core';

import global from './global';
// import portfolio from "./controllers/portfolio";
// import single from "./controllers/single";
// import comingSoon from "./controllers/coming-soon";

const controllers = {
  // 'portfolio': portfolio,
  // 'single': single,
  // 'coming_soon': comingSoon,
};
const _find = (_class, _str) => {
  if (_class.length > 0) {
    let _array = null;
    for (let i = 0; i < _class.length; i++) {
      let n = _class[i].match(/_ph$/i);
      if (n) {
        _array = _class[i].replace('_' + _str, '');
        break;
      }
    }
    return _array;
  }
  else {
    return null;
  }
};

const router = () => {
  const _class = document.body.className.replace(/-/g, '_').split(/\s+/);
  const controller = _find(_class, 'ph');
  new global();
  if (controller !== null) {
    if (controllers[controller]) {
      new controllers[controller]();
    }
  }
};
router();