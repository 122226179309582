import '@popperjs/core';
import 'bootstrap';
import validate from 'jquery.validation';

export default () => {
  $('.openbtn').on('click', function () {
    document.getElementById('myNav').style.height = '100%';
  });
  $('.closebtn').on('click', function () {
    document.getElementById('myNav').style.height = '0%';
  });
  $('.btnForm a').attr('data-bs-toggle', 'modal');
  $('.btnForm a').attr('data-bs-target', '#signIn');
  $('.btnSupport a').attr('data-bs-toggle', 'modal');
  $('.btnSupport a').attr('data-bs-target', '#support');

  $('.custom-select').each(function () {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    let _id = $(this).data('target');
    if(_id.search('table')){
      $(_id + ' .table__new-price').text(numberWithCommas(1500000 * parseInt($(this).val())));
    }
    if(_id.search('card')){
      $(_id + ' .card__new-price').text(numberWithCommas(1500000 * parseInt($(this).val())));
    }
  });
  $('.custom-select').change(function () {
    let _id = $(this).data('target');
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    if(_id.search('table')){
      $(_id + ' .table__new-price').text(numberWithCommas(1500000 * parseInt($(this).val())));
    }
    if(_id.search('card')){
      $(_id + ' .card__new-price').text(numberWithCommas(1500000 * parseInt($(this).val())));
    }
  });
  $('.select-package').click(function () {
    let packageSelect = $(this).data('package');
    $('#form-signIn').append('<input type="hidden" name="package" value="'+ packageSelect +'">');
    $('#signIn').modal('show');
  });
  $('#form-signIn').validate({
    rules:{
      register_username: 'required',
      register_password: 'required',
      register_email:{
        required: true,
        email: true
      },
      number_phone:'required'
    },
    submitHandler:(form) => {
      $.ajax({
        url:'/wp-admin/admin-ajax.php',
        type:'post',
        dataType:'json',
        data: $('#form-signIn').serialize(),
        success:(response) => {
          if(response.status == 1){
            $.ajax({
              url: '/wp-admin/admin-ajax.php',
              type: 'post',
              dataType: 'json',
              data:{
                action: 'send_mail',
                user: response.user_id
              },
              success:(response) => {
                console.log(response);
              }
            });
          }
        }
      });
    }
  });
  $('#form-support #phone').change(function () {
    $('#form-support #register_email').val($('#form-support #phone').val() + '@gmail.com');
    $('#form-support #register_username').val($('#form-support #phone').val());
  });
  $('#form-support #phone').focus(function () {
    $('#error-phone').remove();
  });
  $('#form-support').validate({
    rules:{
      number_phone: 'required'
    },
    submitHandler: (form) => {
      let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      let typingphone = $('#form-support #phone').val();
      if(typingphone.match(phoneno)){
        $('#form-support #phone').next().remove();
        $.ajax({
          url:'/wp-admin/admin-ajax.php',
          type:'post',
          dataType:'json',
          data: $('#form-support').serialize(),
          success:(response) => {
            if(response.status == 1){
              $.ajax({
                url: '/wp-admin/admin-ajax.php',
                type: 'post',
                dataType: 'json',
                data:{
                  action: 'send_mail',
                  user: response.user_id
                },
                success:(response) => {
                  console.log(response);
                }
              });
            }
          }
        });
      }else{
        $('#form-support #error-phone').remove();
        $( '<label class="text-danger" id="error-phone">*Số điện thoại không đúng cú pháp</label>' ).insertAfter( '#form-support #phone' );
      }
    }
  });
  $('#form-usefree').validate({
    rules:{
      name:'required',
      phone:'required',
      email:{
        email:true,
        required: true
      }
    }
  });
};